<template>
    <div v-show="!notFound">
        <div id="product" class="container d-flex gap-4 py-5">
            <img :src="product.image" alt="item-image" width="600" height="400">
            <div class="p-4 d-flex flex-column justify-content-between">
                <div>
                    <h4 class="card-title">{{ product.title }}</h4>
                    <p class="card-text">{{ product.description }}</p>
                    <span class="text-muted fw-bold">₱ {{ product.price }}</span>
                </div>
                <div>
                    <p class="small text-muted mb-4 pb-2">Quantity</p>
                    <div class="d-flex gap-2 align-items-center">
                    <button type="button" class="bg-transparent border-0" @click="decrease" :disabled="quantity <= 1"><i class="bi bi-dash"></i></button>
                    <!-- <input type="number" class="lead fw-normal w-25 border-0 border-bottom border-dark" min="1" disabled> -->
                    <span class="lead fw-normal">{{ quantity }}</span>
                    <button type="button" class="bg-transparent border-0" @click="increase"><i class="bi bi-plus"></i></button>
                    </div>
                </div>
                <span>Total: {{ total.toFixed(2) }}</span>
                <div class="d-flex gap-3 justify-content-end">
                    <router-link to="/products" class="bg-theme rounded p-2 text-white text-decoration-none">Continue Shopping</router-link>
                    <button type="button" class="bg-secondary-theme rounded p-2 text-white" @click="addToCart">
                        <span v-show="!addSuccess">Add to cart</span>
                        <span v-show="addSuccess"><i class="bi bi-check-circle"></i> Successfully added</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div v-show="notFound">
        <div class="d-flex justify-content-center align-content-center py-5" v-show="notFound">
            <h1 class="text-danger">Product Not Found</h1>
        </div>
    </div>
</template>

<script>
// import { setCookie, getCookie, deleteCookie } from "../assets/js/create-cookie"
export default {
    name: 'Product',
    data() {
        return {
            product: localStorage.getItem('current_view') ? JSON.parse(localStorage.getItem('current_view')) : {},
            quantity: 1,
            addSuccess: false,
            notFound: false,
            total: 1
        }
    },
    mounted() {
        document.getElementById('app').scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
        if(this.$route.params.name != this.product.link) {
            localStorage.removeItem('current_view')
        }
        
        if(!localStorage.getItem('current_view')) {
            const obj = Object.values(this.$store.state.products)
            if(obj.find((item) => item.link === this.$route.params.name)) {
                this.product = obj.find((item) => item.link === this.$route.params.name)
                localStorage.setItem('current_view', JSON.stringify(this.product), 2)
            }
            else this.notFound = true
        }

        this.total = this.quantity * this.product.price
    },
    methods: {
        increase() {
            this.quantity += 1
            this.total = this.product.price * this.quantity
        },
        decrease() {
            if(this.quantity <= 1) return
            this.quantity -= 1
            this.total = this.product.price * this.quantity
        },
        addToCart() {
            var new_cookie = {}
            this.product.quantity = this.quantity
            this.product.total = this.product.price * this.product.quantity
            if(localStorage.getItem('cart')) {
                var found = false
                Object.assign(new_cookie, JSON.parse(localStorage.getItem('cart')))
                for(var item in new_cookie) {
                    if(new_cookie[item].link === this.$route.params.name) {
                        new_cookie[item].quantity += this.quantity
                        new_cookie[item].total = new_cookie[item].quantity * new_cookie[item].price
                        found = true
                    }
                }
                if(!found) {
                    Object.assign(new_cookie, { 
                    [Object.values(JSON.parse(localStorage.getItem('cart'))
                    ? Object.values(JSON.parse(localStorage.getItem('cart')))
                    : 0)
                    .length]: this.product })
                }
            }
            else {
                Object.assign(new_cookie, { 
                    [Object.values(JSON.parse(localStorage.getItem('cart'))
                        ? Object.values(JSON.parse(localStorage.getItem('cart')))
                        : 0)
                        .length]: this.product })
            }
            localStorage.setItem('cart', JSON.stringify(new_cookie))
            this.$store.commit('hasCart_true')
            this.addSuccess = true

            setTimeout(() => this.addSuccess = false, 1500)
        }
    }
}
</script>